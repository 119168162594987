import { Directive } from '@angular/core';

@Directive({
  selector: '[appAlphabateOnly]'
})
export class AlphabateOnlyDirective {
  constructor() {
  }

}
