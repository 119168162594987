export class Doclogindtl {
     address: string;
     countryName: string; 
     latitude: string;
     loginMode: string;
     loginPwd: string;
     longitude: string;
     machineIpAddress: string;
     sessionId: string;
     userId: string;
}
