export class User {
  degreeCertificateImgFileData: string;
  degreeCertificateImgUrl: string;
  docSignFileName: string;
  docSignUrl: string;
  doctorId: string;
  expYr: string;
  litDocSpecalizeId: string;
  loginStatus: string;
  profileImgFileData: string;
  profileImgUrl: string;
  rejectionNote: string;
  resMsg: string;
  resStatus: string;
  sessionId: string;
  specializationName: string;
  userCustomerId: string;
  userFname: string;
  userLname: string;
  userMailId: string;
  userMname: string;
  userMobileNo: string;
  userQualification: string;
  userRegnId: string;
  renderEcgIntp: string;
  renderEarning: string;
  renderPrescriptionToReview: string;
  renderPrescriptionReviewed: string;
  renderQueryFromPatient: string;
  renderPostFromYou: string;
  renderNextAvailableTime: string
  renderExotelCall: string;
  mobileVerficationFlag: string;
  pharmaId: string;
  renderFavouriteRx: string;
  clientId: string;
}
