export class ResponseDto {
    private returnValue: string;
    private message: string;
    private status: string;

    public getReturnValue(): string {
        return this.returnValue;
    }

    public setReturnValue(returnValue: string): void {
        this.returnValue = returnValue;
    }

    public getMessage(): string {
        return this.message;
    }

    public setMessage(message: string): void {
        this.message = message;
    }

    public getStatus(): string {
        return this.status;
    }

    public setStatus(status: string): void {
        this.status = status;
    }

}
