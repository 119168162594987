// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //hellolyf app firbase data
  // firebase: {
  //   apiKey: "AIzaSyB8WNoYNvB03R7izKSduXGXG2RwKKAk208",
  //   authDomain: "hellolyf-doc.firebaseapp.com",
  //   databaseURL: "https://hellolyf-doc.firebaseio.com",
  //   projectId: "hellolyf-doc",
  //   storageBucket: "hellolyf-doc.appspot.com",
  //   messagingSenderId: "979441749316"
  // }


  //testing ===>
  // firebase: {
  //   apiKey: "AIzaSyCJF53ZLyA77pmUvDHVueCTxB-H26Nz_OQ",
  //   authDomain: "litmusdx-93d26.firebaseapp.com",
  //   databaseURL: "https://litmusdx-93d26.firebaseio.com",
  //   projectId: "litmusdx-93d26",
  //   storageBucket: "litmusdx-93d26.appspot.com",
  //   messagingSenderId: "1064503239315",
  //   appId: "1:1064503239315:web:aa081b0f4387a598e1a366",
  //   measurementId: "G-2T6E0J3NEW"
  // }

  //live
  firebase: {
    apiKey: "AIzaSyB8WNoYNvB03R7izKSduXGXG2RwKKAk208",
    authDomain: "hellolyf-doc.firebaseapp.com",
    databaseURL: "https://hellolyf-doc.firebaseio.com",
    projectId: "hellolyf-doc",
    storageBucket: "hellolyf-doc.appspot.com",
    messagingSenderId: "979441749316",
    appId: "1:979441749316:web:21c760248bd859ca391b11"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
