export class DoctorContactDto {
    private countryCode: string;
    private mobileNo: string;
    private otp: string;
    private smsSender: string;

    public getCountryCode(): string {
        return this.countryCode;
    }

    public setCountryCode(countryCode: string): void {
        this.countryCode = countryCode;
    }

    public getMobileNo(): string {
        return this.mobileNo;
    }

    public setMobileNo(mobileNo: string): void {
        this.mobileNo = mobileNo;
    }

    public getOtp(): string {
        return this.otp;
    }

    public setOtp(otp: string): void {
        this.otp = otp;
    }

    public getSmsSender(): string {
        return this.smsSender;
    }

    public setSmsSender(smsSender: string): void {
        this.smsSender = smsSender;
    }
}
