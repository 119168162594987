import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseDto } from '../model/response-dto';
import { DocActivityRequestDto } from '../model/doc-activity-request-dto';
import { DocDashboardDto } from '../model/doc-dashboard-dto';
import { ApiConstants } from '../model/api-constants';
import { SymptomShortDto } from '../model/symptom-short-dto';
import { GenericDtoWflag } from '../model/generic-dto-wflag';
import { MedDurationDto } from '../model/med-duration-dto';
import { MedFrequencyDto } from '../model/med-frequency-dto';
import { NurseDashboardDto } from '../model/nurse-dashboard-dto';
import { StringDecoder } from 'string_decoder';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private http: HttpClient
  ) {

  }

  check() {
    //console.log(obj)
    alert('dashboard called');
  }



  getDashboardData(): Observable<DocDashboardDto> {
    let sessionObj = JSON.parse(sessionStorage.getItem("LITMUS_USER_DETAILS"));

    let headers: HttpHeaders = new HttpHeaders();

    let sessionId = String(sessionObj['sessionId']);
    headers = headers.append('sessionId', sessionId);

    return this.http.post<DocDashboardDto>(ApiConstants.apiEndPoint + "/docDashboard/doctorData", String(sessionObj['doctorId']), { headers });
  }

  // doctor data from api using doctor id::-
  getDoctorEarningData(doctorId: string): Observable<any> {
    return this.http.get<any>(ApiConstants.apiEndPoint + "/docearning/myearingsdtl/" + doctorId);
  }

  //get all symtoms List from api====>
  getSymptomListFormApi(reqDto: { gender: string; pharmaId: string; searchString: string; }): Observable<SymptomShortDto[]> {
    //console.log("req dto to get dosages::", reqDto);
    return this.http.post<SymptomShortDto[]>(ApiConstants.apiEndPoint + "/pharmacopoeia/symptoms", reqDto);
  }

  // get form driven dosages
  getFormDrivenDosages(pharmaId: any): Observable<any> {
    return this.http.post<DocDashboardDto>(ApiConstants.apiEndPoint + "/pharmacopoeia/formdrivendosage", String(pharmaId));
    //return this.http.post<any>(ApiConstants.apiEndPoint + "/pharmacopoeia/formdrivendosage", "");
  }

  // generic list form api full and favourite list
  getGenericList(reqGenDto: { centerId: string; pharmaId: string; doctorId: string; }): Observable<GenericDtoWflag[]> {
    return this.http.post<GenericDtoWflag[]>(ApiConstants.apiEndPoint + "/pharmacopoeia/generics", reqGenDto);
  }

  // get all FrequencyList for medicine from api ===>
  getFrequencyList(pharmaId: string): Observable<MedFrequencyDto[]> {
    //return this.http.get<MedFrequencyDto[]>(ApiConstants.apiEndPoint + "/generics/frequency");
    return this.http.post<MedFrequencyDto[]>(ApiConstants.apiEndPoint + "/pharmacopoeia/frequency", String(pharmaId));
  }

  //get all Durationlist for medicine from api ===>
  getDurationList(): Observable<MedDurationDto[]> {
    return this.http.get<MedDurationDto[]>(ApiConstants.apiEndPoint + "/generics/duration");
  }

  //get all Durationlist for medicine from api ===>
  getDurationListwithPharma(pharmaId:string): Observable<MedDurationDto[]> {
    return this.http.post<MedDurationDto[]>(ApiConstants.apiEndPoint + "/pharmacopoeia/durationwithpharma", String(pharmaId));
  }

  // get all appointment request to doctor by doctor id
  getRequestedAppointmentListapi(doctorId: any): Observable<any> {
    return this.http.get<any>(ApiConstants.apiEndPoint + "/appointmentRequest/" + doctorId);
  }

  // confirm appointment by doctor
  confirmAppointmentTime(requestDto: { appointmentDate: string; appointmentId: string; appointmentTime: string; doctorId: string; patientId: string; }): Observable<any> {
    return this.http.post<any>(ApiConstants.apiEndPoint + "/confirmAppiontmentRequest", requestDto);
  }

  // mp client generic list
  getShortGenericListMPClient(): Observable<GenericDtoWflag[]> {
    return this.http.get<GenericDtoWflag[]>(ApiConstants.apiEndPoint + "/pharmacopoeia/mpgenerics");
  }
  getNurseData(userCustomerId:string): Observable<any> {
    let dto = {};
    return this.http.post<NurseDashboardDto>(ApiConstants.apiEndPoint + "/docDashboard/nurseData/" + userCustomerId,dto);
  }

  getDoctorFaceIdByDoctorId(doctorId:string): Observable<String> {
    return this.http.get<String>(ApiConstants.apiEndPoint + `/doctorFaceRegController/getDoctorFaceIdByDoctorId/${doctorId}`);
  }

}
