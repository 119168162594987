import { Injectable } from '@angular/core';
const TOKEN = '';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  setAuthKey(key: string, value: string):void {
    sessionStorage.setItem(key, value);
    // console.log("@@@@@key:"+key+"    ####value:"+value);
  }

  isLoggedIn() {
    // console.log("TEST TEST LOOK FOR KEY::");
    // console.log(sessionStorage.getItem("LITMUS_USER_DETAILS") !== null);
    return (sessionStorage.getItem("LITMUS_USER_DETAILS") !== null);
  }

  clearItem(key: string):void {
    sessionStorage.removeItem(key);
  }
  
}






  
  

