import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogOpenService {
    private modals: any[] = [];

    add(modal: any) {
        // add modal to array of active modals
        //alert("add fun called at service");
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        //alert("remove fun called at service");
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string) {
        // open modal specified by id
       // alert("openmodal fun called at service");
        let modal: any = this.modals.filter(x => x.id === id)[0];
        modal.open();
    }

    close(id: string) {
        // close modal specified by id
       // alert("closemodal fun called at service");
        let modal: any = this.modals.filter(x => x.id === id)[0];
        modal.close();
    }
}
