<section class="patient-brief patient-brief-fixed">
  <div class="row">
    <div class="col-4">
      <img src="./assets/images/logo_litmusDx.svg" height="auto" style="margin-top: 5px;" class="navbar-brand"
        style="max-width: 125px;" />
    </div>
    <div class="col-8">
      <span style="float:right; padding-top:5px; padding-right:10px; font:medium;"> <a routerLink="/login">
          <i class="fas fa-sign-in-alt"></i> {{ "HOME.Login" | translate }}
        </a></span>
    </div>
  </div>
</section>

<section class="section-padding gray-bg padding-bottom-90">
  <div class="container">
    <div style="padding-top:20px;">br</div>
    <div class="col-md-12 text-center">
      <h1 class="font-40 mb-20">Privacy Policy of <br>Glocal Healthcare Systems Pvt. Ltd.</h1>
    </div>
    <div class="col-md-12 pp_section">
      <div class="basic-info">
        <p>Last updated: November 19, 2020</p>
        <p>Privacy Policy of the Company and all its subsidiaries and assets including those in different countries
          (“Privacy Policy”). The Company delivers healthcare to people through hospitals, Digital Dispensaries and
          Telemedicine and also assists partners including healthcare organizations, governments and others to deliver
          healthcare and allied services. It needs access to data to enable delivery of services and also improve its
          services. Every transaction will entail transferring in the case of a patient or person accessing services,
          his/her personal / clinical data to health professionals including ancillary support which may include
          non-clinical persons like interpreters, emergency call centers like ambulance services, pharmacies, clinical
          laboratories & healthcare aggregators - to enable service delivery and coordination of care as required, to
          the health planning, insurance companies or other agencies responsible for transferring payments or
          reimbursements, and in appropriate conditions and as per law, to public health authorities in compliance with
          law and in the case of healthcare professionals, their credentials and personal data to the service recipient
          to enable the delivery of services and also to payment processing, coordination of care etc. The Company also
          uses automated algorithms / Internet of Things / Point of Care devices to improve healthcare delivery through
          LitmusDx, Hellolyf, hellolyfDr, HellolyfCX, Hellolyf MX, HellolyfRX; however all such devices, algorithms are
          used only to highlight to a limited extent possible medical errors to the healthcare professional and any
          decision to provide a specific advice lies only with the duly accredited or certified medical professional and
          the decision to give a specific advice or recommend a treatment rests with the clinician or medical
          professional or advisor and accordingly, the Company does not take responsibility for medical decisions. The
          hardware, software and data used is only used to attempt reduction of medical errors and it is a work in
          progress and does not either force or recommend a specific final advice and is limited to flagging
          possibilities to a human decision maker.</p>
        <p>This Privacy Policy describes Our policies and procedures on the collection, use, storage, processing,
          transfer, and disclosure of Your Personal Data when You use the Service and tells You about Your privacy
          rights and how the law protects You.</p>
        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
          collection and use of information in accordance with this Privacy Policy.</p>
      </div>

      <h3 class="font-40 mb-20 sub-heading">Interpretation and Definitions</h3>

      <div class="panel-group feature-accordion brand-accordion dd-faq icon angle-icon" id="basic">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              <a data-toggle="collapse" data-parent="#basic" href="#basic-1">
                Interpretation
              </a>
            </h3>
          </div>
          <div id="basic-1" class="panel-collapse collapse in">
            <div class="panel-body">
              <p>The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.</p>
            </div>
          </div>
        </div>


        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              <a class="collapsed" data-toggle="collapse" data-parent="#basic" href="#basic-2">
                Definitions
              </a>
            </h3>
          </div>
          <div id="basic-2" class="panel-collapse collapse">
            <div class="panel-body">
              <h4 class="inner-heading"><strong>For the purposes of this Privacy Policy:</strong></h4>

              <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our
                Service.</p>

              <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                with a party, where "control" means ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other managing authority.</p>
              <p><strong>Application</strong> means the software program / app / webapp provided by the Company
                downloaded by You on any electronic device, named Hellolyf, HellolyfDr, LitmusDX and on url beginning
                with <a href="http://www.hellolyf.com/">www.hellolyf.com</a>, <a
                  href="http://www.litmusdx.com/">www.litmusdx.com</a>, <a
                  href="http://www.ghspl.com/">www.ghspl.com</a>, <a
                  href="http://www.medikate.org/">www.medikate.org</a>, <a
                  href="http://www.zoyel.com/">www.zoyel.com</a>, <a
                  href="http://www.glocal.healthcare/">www.glocal.healthcare </a>.</p>
              <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                refers to Glocal Healthcare Systems Private Limited, 3B 207 Ecospace Business Park, New Town, Action
                Area II, Kolkata .</p>
              <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other
                device by a website, containing the details of Your browsing history on that website among its many
                uses.</p>

              <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or
                a digital tablet.</p>
              <p><strong>Personal Data</strong> means any information that relates to a natural person which, either
                directly or indirectly, is capable of identifying such person and for the purpose of this Privacy
                Policy, refers to the Personal Data identified herein under the head ‘Type of Data Collected’.</p>
              <p><strong>Service</strong> refers to the Application or the Website or both.</p>

              <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of
                the Company. It refers to third-party companies or individuals employed by the Company to facilitate the
                Service, to provide the Service on behalf of the Company, to perform services related to the Service or
                to assist the Company in analyzing how the Service is used.</p>
              <p><strong>Third-party Social Media Service</strong> refers to any website or any social network website
                through which a User can log in or create an account to use the Service.</p>


              <p><strong>Usage Data </strong> refers to data collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example, the duration of a page visit). </p>


              <p><strong>Website</strong>refers to Hellolyf, accessible from <a
                  href="https://www.hellolyf.com/">www.hellolyf.com</a></p>

              <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other
                legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>



            </div>
          </div>
        </div>

        <h3 class="font-40 mb-20 sub-heading"> Privacy Policy under the Information Technology Act, 2000 – India</h3>
        <p>This Privacy Policy is an electronic record under the Information Technology Act, 2000 and the rules made
          thereunder. This Privacy Policy does not require any physical, electronic, or digital signature by the
          Company. </p>

        <h3 class="font-40 mb-20 sub-heading"> Collecting and Using Your Personal Data</h3>

        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              <a class="collapsed" data-toggle="collapse" data-parent="#basic" href="#basic-3">
                Types of Data Collected
              </a>
            </h3>
          </div>
          <div id="basic-3" class="panel-collapse collapse">
            <div class="panel-body">

              <h4 class="inner-heading"><strong>Personal Data</strong></h4>
              <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information
                that can be used to contact or identify You, including but not limited to:</p>

              <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Gender </li>
                <li>Age</li>
                <li>Phone number</li>
                <li>Location</li>
                <li>Device</li>
                <li>Address, State, Province, ZIP/Postal code, City, country</li>
                <li>Bank account information in order to pay for products and/or services within the Service</li>
                <li>Usage Data</li>
                <li>Medical / Clinical data necessary for the Clinical / Medical / Healthcare Professional to give his/
                  her advice including, but not limited to, information relating to Your (i) physical, physiological and
                  mental health condition and (ii) medical records and history</li>
                <li>Medical Council Registration Number or the National Board of Accreditation as appropriate for the
                  country of Your licensing as a medical / clinical professional, Degrees , Diplomas, Certificates or
                  other qualifications or experience in case You are using the services as a professional to provide
                  clinical or medical advice</li>
                <li>Any detail relating to the aforesaid as provided to the Company for availing the Services</li>

              </ul>

              <p>It is hereby clarified that any information that is freely available or accessible in public domain or
                furnished under the (Indian) Right to Information Act, 2005 or any other law for the time being in force
                shall not be regarded as ‘personally identifiable information’.</p>
              <p>When You pay for a product and/or a service via bank transfer, We may ask You to provide information to
                facilitate this transaction and to verify Your identity. Such information may include, without
                limitation:</p>

              <ul>
                <li>Date of birth</li>
                <li>Passport or National ID card or other form of ID</li>
                <li>Bank card statement</li>
                <li>Other information linking You to an address</li>
              </ul>
              <p>You always have the option to not provide the Personal Data or information to Us sought to be collected
                from You by choosing to not use a particular Service(s) or feature being provided by Us, which requires
                You to provide such information. However, any refusal to provide certain Personal Data or information
                could impact provision of services and restrict your access thereto.</p>


              <h4 class="inner-heading"><strong>Usage Data</strong></h4>
              <p>Usage Data is collected automatically when using the Service.</p>
              <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
                the time spent on those pages, unique device identifiers and other diagnostic data.</p>
              <p>When You access the Service by or through a mobile device, We may collect certain information
                automatically, including, but not limited to, the type of mobile device You use, Your mobile device
                unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
                Internet browser You use, unique device identifiers and other diagnostic data.</p>
              <p>We may also collect information that Your browser sends whenever You visit our Service or when You
                access the Service by or through a mobile device.</p>



              <h4 class="inner-heading"><strong>Information Collected while Using the Application</strong></h4>
              <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your
                prior permission:</p>
              <p><i>Information regarding Your location
                  Pictures, videos and other information from Your or accessing Device's camera and photo library
                </i></p>
              <p>We use this information to provide features of Our Service and to improve and customize Our Service.
                The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be
                simply stored on Your device.</p>
              <p>You can enable or disable access to this information at any time, through Your Device settings.</p>


              <h4 class="inner-heading"><strong>Tracking Technologies and Cookies</strong></h4>
              <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
                information. Tracking technologies used are beacons, tags, and scripts to collect and track information
                and to improve and analyze Our Service. The technologies We use may include:</p>

              <ul>

                <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can
                  instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if
                  You do not accept Cookies, You may not be able to use some parts of Our Service. Unless You have
                  adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                <li><strong>Flash Cookies.</strong> Certain features of our Service may use local stored objects (or
                  Flash Cookies) to collect and store information about Your preferences or Your activity on our
                  Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies.
                  For more information on how You can delete Flash Cookies, please read "Where can I change the settings
                  for disabling, or deleting local shared objects?" available at <a
                    href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                    target="_blank">https://helpx.adobe.com/ flash-player/kb/ disable-local-shared-objects-flash.html
                    #main_Where_can_I_change_the _settings_for_disabling __or_deleting_local_ shared_objects_</a></li>
                <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small
                  electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
                  gifs) that permit the Company, for example, to count users who have visited those pages or opened an
                  email and for other related website statistics (for example, recording the popularity of a certain
                  section and verifying system and server integrity).</li>
              </ul>
              <p><i>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal
                  computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close
                  Your web browser. Learn more about cookies: What Are Cookies?.</i></p>
              <p>We use both Session and Persistent Cookies for the purposes set out below:</p>

              <h5 class="inner-heading">Necessary / Essential Cookies</h5>
              <ul>
                <li>
                  <strong>Type:</strong> Session Cookies
                </li>
                <li>
                  <strong>Administered by:</strong> Us
                </li>
                <li>
                  <strong>Purpose:</strong> These Cookies are essential to provide You with services available through
                  the Website and to enable You to use some of its features. They help to authenticate users and prevent
                  fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with those services.
                </li>

              </ul>

              <h5 class="inner-heading">Cookies Policy / Notice Acceptance Cookies</h5>
              <ul>
                <li>
                  <strong>Type:</strong> Persistent Cookies
                </li>
                <li>
                  <strong>Administered by:</strong> Us
                </li>
                <li>
                  <strong>Purpose:</strong> These Cookies identify if users have accepted the use of cookies on the
                  Website.
                </li>

              </ul>

              <h5 class="inner-heading">Functionality Cookies</h5>
              <ul>
                <li>
                  <strong>Type:</strong> Persistent Cookies
                </li>
                <li>
                  <strong>Administered by:</strong> Us
                </li>
                <li>
                  <strong>Purpose:</strong> These Cookies allow us to remember choices You make when You use the
                  Website, such as remembering Your login details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid You having to re-enter Your preferences
                  every time You use the Website.
                </li>

              </ul>

              <p>For more information about the cookies We use and Your choices regarding cookies, please visit our
                Cookies Policy or the Cookies section of our Privacy Policy.</p>


            </div>
          </div>
        </div>

        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              <a class="collapsed" data-toggle="collapse" data-parent="#basic" href="#basic-4">
                Use of Your Personal Data
              </a>
            </h3>
          </div>
          <div id="basic-4" class="panel-collapse collapse">
            <div class="panel-body">

              <h4 class="inner-heading"><strong>The Company may use Personal Data for the following purposes:</strong>
              </h4>

              <p><strong>To provide and maintain our Service,</strong> including to monitor the usage of our Service.
              </p>
              <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The
                Personal Data You provide can give You access to different functionalities of the Service that are
                available to You as a registered user.</p>
              <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the
                purchase contract for the products, items or services You have purchased or of any other contract with
                Us through the Service.</p>
              <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent
                forms of electronic communication, such as a mobile application's push notifications regarding updates
                or informative communications related to the functionalities, products or contracted services, including
                the security updates, when necessary or reasonable for their implementation.</p>
              <p><strong>To provide You</strong> with news, special offers and general information about other goods,
                services and events which We offer that are similar to those that You have already purchased or enquired
                about unless You have opted not to receive such information.</p>
              <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
              <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our
                assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in
                which Personal Data held by Us about our Service users is among the assets transferred.</p>
              <p><strong>For Research & Development :</strong> We may use Your information for development of better
                services through Clinical Trials, cohort studies, AI, Machine Learning, Analytics to provide continuous
                improvement of algorithms, to develop medicines, vaccines, protocols, designs to ensure better delivery.
                However, any physical risks to You to enter into a clinical trial would be explained and a specific
                consent would be taken as per law separately.</p>
              <p><strong>For other purposes:</strong> We may use Your information for other purposes, such as data
                analysis, audit, identifying usage trends, determining the effectiveness of our promotional campaigns
                and to evaluate and improve our Service, products, services, marketing and Your experience.</p>

              <h4 class="inner-heading"><strong>We may share Your Personal Data in the following situations:</strong>
              </h4>

              <ul>
                <li><strong>With Service Providers / Recipients of Service: </strong> We may share Your Personal Data
                  with Service Providers / Service Recipients to monitor and analyze the use of our / Your Service, for
                  payment processing, to contact You and to enable You to provide / receive services. All agencies or
                  persons involved in the coordination of care may be provided access to data.</li>

                <li><strong>With Your Medical Insurance / Social Health Insurance Company / Health Management
                    Organization or Equivalent:</strong> where it is required to get reimbursement or claims regarding
                  service delivery to You / for You in case You are a medical / clinical service provider.</li>

                <li><strong>With Government Authorities : </strong> where required by law to comply with notifiable
                  diseases communication laws in force or equivalent in the country of origin or jurisdiction to meet
                  with.</li>

                <li><strong>For business transfers:</strong> We may share or transfer Your Personal Data in connection
                  with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all
                  or a portion of Our business to another company.</li>

                <li><strong>With Affiliates: </strong>We may share Your information with Our Affiliates, in which case
                  We will require those Affiliates to honor this Privacy Policy. Affiliates may include Our parent
                  company and any other subsidiaries, joint venture partners or other companies that We control or that
                  are under common control with Us.</li>

                <li><strong>With business partners: </strong>We may share Your information with Our business partners to
                  offer You certain products, services or promotions.</li>

                <li><strong>With other users: </strong>when You share Personal Data or otherwise interact in the public
                  areas with other users, such information may be viewed by all users and may be publicly distributed
                  outside. If You interact with other users or register through a Third-Party Social Media Service, Your
                  contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description
                  of Your activity. Similarly, other users will be able to view descriptions of Your activity,
                  communicate with You and view Your profile.</li>

                <li><strong>With Your consent: </strong>We may disclose Your Personal Data for any other purpose with
                  Your consent.</li>
              </ul>


            </div>
          </div>
        </div>

        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              <a class="collapsed" data-toggle="collapse" data-parent="#basic" href="#basic-5">
                Retention of Your Personal Data
              </a>
            </h3>
          </div>
          <div id="basic-5" class="panel-collapse collapse">
            <div class="panel-body">
              <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
                this Privacy Policy or to meet requirements of law in the relevant jurisdiction. We will retain and use
                Your Personal Data to the extent necessary to comply with our legal obligations (for example, if We are
                required to retain Your data to comply with applicable laws), resolve disputes, and enforce our legal
                agreements and policies.</p>
              <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
                retained for a shorter period of time, except when this data is used to strengthen the security or to
                improve the functionality of Our Service, or We are legally obligated to retain this data for longer
                time periods.</p>
            </div>
          </div>
        </div>

        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              <a class="collapsed" data-toggle="collapse" data-parent="#basic" href="#basic-6">
                Transfer of Your Personal Data
              </a>
            </h3>
          </div>
          <div id="basic-6" class="panel-collapse collapse">
            <div class="panel-body">
              <p>Your information, including Personal Data, is processed at the Company's operating offices and in any
                other places where the parties involved in the processing are located. It means that this information
                may be transferred to — and maintained on — computers located outside of Your state, province, country
                or other governmental jurisdiction where the data protection laws may differ than those from Your
                jurisdiction.</p>
              <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your
                agreement to that transfer.</p>
              <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and
                in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
                organization or a country unless there are adequate controls in place including the security of Your
                data and other Personal Data.</p>
            </div>
          </div>
        </div>

        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              <a class="collapsed" data-toggle="collapse" data-parent="#basic" href="#basic-7">
                Disclosure of Your Personal Data
              </a>
            </h3>
          </div>
          <div id="basic-7" class="panel-collapse collapse">
            <div class="panel-body">
              <h4 class="inner-heading"><strong>Business Transactions</strong></h4>
              <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
                transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a
                different Privacy Policy.</p>

              <h4 class="inner-heading"><strong>Law enforcement</strong></h4>
              <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to
                do so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency).</p>

              <h4 class="inner-heading"><strong>Other legal requirements</strong></h4>
              <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
              </p>

              <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
              </ul>

            </div>
          </div>
        </div>

        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              <a class="collapsed" data-toggle="collapse" data-parent="#basic" href="#basic-8">
                Security of Your Personal Data
              </a>
            </h3>
          </div>
          <div id="basic-8" class="panel-collapse collapse">
            <div class="panel-body">
              <p>The Company has reasonable security measures and safeguards in place to protect Your privacy and
                Personal Data from loss, misuse, unauthorized access, disclosure, destruction, and alteration, in
                compliance with applicable laws. The security of Your Personal Data is important to Us, but remember
                that no method of transmission over the Internet, or method of electronic storage is 100% secure. While
                We strive to use commercially acceptable means to protect Your Personal Data, We cannot however ensure
                or guarantee or warrant its absolute security. It is further clarified that You have and so long as You
                access the Services (directly or indirectly), the obligation to ensure that You shall at all times, take
                adequate physical, managerial, and technical safeguards, at Your end, to preserve the integrity and
                security of Your data which shall include and not be limited to Your Personal Data.</p>


            </div>
          </div>
        </div>

        <h3 class="font-40 mb-20 sub-heading"> Detailed Information on the Processing of Your Personal Data</h3>
        <p><i>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are
            obligated not to disclose or use it for any other purpose.</i></p>

        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              <a class="collapsed" data-toggle="collapse" data-parent="#basic" href="#basic-9">
                Payments
              </a>
            </h3>
          </div>
          <div id="basic-9" class="panel-collapse collapse">
            <div class="panel-body">
              <p>We may provide paid products and/or services within the Service. In that case, We may use third-party
                services for payment processing (e.g. payment processors).</p>
              <p>We will not store or collect Your payment card details. That information is provided directly by You to
                Our third-party payment processors whose use of Your Personal Data is governed by their Privacy Policy.
                These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards
                Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
                requirements help ensure the secure handling of payment information.</p>
              <ul>
                <li><strong>Stripe</strong> <br>Their Privacy Policy can be viewed at <a
                    href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a></li>

                <li><strong>PayPal</strong> <br>Their Privacy Policy can be viewed at <a
                    href="https://www.paypal.com/webapps/mpp/ua/privacy-full">https://www.paypal.com/
                    webapps/mpp/ua/privacy-full</a></li>

                <li><strong>RazorPay</strong> <br>Their Privacy Policy can be viewed at <a
                    href="https://razorpay.com/privacy/">https://razorpay.com/privacy/</a></li>

                <li><strong>PayUMoney</strong> <br>Their Privacy Policy can be viewed at <a
                    href="https://test.payumoney.com/privacypolicy.html">https://test.payumoney.com/
                    privacypolicy.html</a></li>

                <li><strong>PayTM</strong> <br>Their Privacy Policy can be viewed at <a
                    href="https://pages.paytm.com/privacy.html">https://pages.paytm.com/
                    privacy.html</a></li>
                <li><strong>Selcom</strong> <br>Their Privacy Policy can be viewed at <a
                    href="https://www.selcom.net/selcom-privacy-policy">https://www.selcom.net/selcom-privacy-policy</a>
                </li>

              </ul>
              <p>When You use Our Service to pay for a product and/or service via bank transfer, We may ask You to
                provide information to facilitate this transaction and to verify Your identity.</p>
              <p>When payment information is being transmitted for availing the Services, it will be protected by
                encryption technology. By making payments for any Services on Our platform, You expressly consent to the
                sharing of Your information with third party service providers, including payment gateways, to process
                payments and manage Your payment-related information. Hence, the Company cannot guarantee that
                transmissions of Your payment-related information or Personal Data will always be secure or that
                unauthorized third parties will never be able to defeat the security measures taken by the Company or
                the Company’s third-party service providers. The Company assumes no liability or responsibility for
                disclosure of Your information due to errors in transmission, unauthorized third-party access, or other
                causes beyond its control. You play an important role in keeping Your Personal Data secure. You shall
                not share Your Personal Data or other security information for Your account with anyone. The Company has
                undertaken reasonable measures to protect Your rights of privacy with respect to Your access of Our
                Services. However, We shall not be liable for any unauthorized or unlawful disclosures of Your Personal
                Data made by any third parties who are not subject to Our control.</p>




            </div>
          </div>
        </div>

        <h3 class="font-40 mb-20 sub-heading">Other Service Partners</h3>
        <p>Other service partners which may have access to some or part of your data to enable services in certain
          circumstances and these may include the following. Please review their privacy policies. </p>
        <ul>
          <li><a href="https://www.tawk.to/privacy-policy/" target="_blank">https://www.tawk.to/privacy-policy/</a></li>
          <li><a href="https://exotel.com/privacy-policy-2018/"
              target="_blank">https://exotel.com/privacy-policy-2018/</a></li>
          <li><a href="https://www.textlocal.in/privacy/" target="_blank">https://www.textlocal.in/privacy/</a></li>
          <li><a href="https://www.plivo.com/legal/privacy/" target="_blank">https://www.plivo.com/legal/privacy/</a>
          </li>
          <li><a href="https://www.tableau.com/privacy" target="_blank">https://www.tableau.com/privacy</a></li>
          <li><a href="https://policies.google.com/privacy?gl=IN&hl=en"
              target="_blank">https://policies.google.com/privacy?gl=IN&hl=en</a></li>
          <li><a href="https://aws.amazon.com/privacy/" target="_blank">https://aws.amazon.com/privacy/</a></li>
          <li><a href="https://in.godaddy.com/legal/agreements/privacy-policy"
              target="_blank">https://in.godaddy.com/legal/agreements/
              privacy-policy</a></li>
        </ul>

        <h3 class="font-40 mb-20 sub-heading">Your California Privacy Rights (California's Shine the Light law)</h3>
        <p>Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an
          established business relationship with us can request information once a year about sharing their Personal
          Data with third parties for the third parties' direct marketing purposes.</p>
        <p>If You'd like to request more information under the California Shine the Light law, and if You are a
          California resident, You can contact Us using the contact information provided below.</p>


        <h3 class="font-40 mb-20 sub-heading">California Privacy Rights for Minor Users (California Business and
          Professions Code Section 22581)</h3>
        <p>California Business and Professions Code section 22581 allow California residents under the age of 18 who are
          registered users of online sites, services or applications to request and obtain removal of content or
          information they have publicly posted.</p>
        <p>To request removal of such data, and if You are a California resident, You can contact Us using the contact
          information provided below, and include the email address associated with Your account.</p>
        <p>Be aware that Your request does not guarantee complete or comprehensive removal of content or information
          posted online and that the law may not permit or require removal in certain circumstances.</p>


        <h3 class="font-40 mb-20 sub-heading">Links to Other Websites</h3>
        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party
          link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of
          every site You visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
          third party sites or services.</p>



        <h3 class="font-40 mb-20 sub-heading">Your Consent and Changes to this Privacy Policy</h3>
        <p><b>Your acknowledgement:</b> All information disclosed by You shall be deemed to be disclosed willingly and
          without any coercion. No liability pertaining to the authenticity / genuineness / misrepresentation / fraud /
          negligence of the information disclosed by You shall lie on the Company nor will the Company be in any way
          responsible to verify any information obtained from You.</p>
        <p><b>Changes to Our Privacy Policy:</b> We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.</p>
        <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
          effective and update the "Last updated" date at the top of this Privacy Policy.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page. Your acceptance of the amended Privacy Policy including by
          way of Your continuous access to the Services shall signify Your consent to such changes and agreement to be
          legally bound by the same.</p>


        <h3 class="font-40 mb-20 sub-heading">Contact Us</h3>
        <p>Our Company would like to make sure You are fully aware of all of your data protection rights. Every user is
          entitled to the following:</p>
        <br>
        <p>The right to access – You have the right to request Our Company for separate copies of your personal data. We
          may charge you a small fee for this service.</p>
        <p>The right to rectification – You have the right to request that Our Company correct any information You
          believe is inaccurate. You also have the right to request Our Company to complete the information you believe
          is incomplete.</p>
        <p>The right to erasure – You have the right to request that Our Company erase your personal data, under certain
          conditions.</p>
        <p>The right to restrict processing – You have the right to request that Our Company restrict the processing of
          your personal data, under certain conditions.</p>
        <p>The right to object to processing – You have the right to object to Our Company’s processing of Your personal
          data, under certain conditions.</p>
        <p>The right to data portability – You have the right to request that Our Company transfer the data that We have
          collected to another organization, or directly to You, under certain conditions.</p>
        <p>The right to withdraw consent – You may choose to withdraw Your consent in writing subject to this Privacy
          Policy at any point of time. In case You do not provide Your consent or later withdraw Your consent, We
          request You not to access and use the Services and We also reserve the right to not provide You any Services.
          In such a scenario, the Company may delete Your information (personal or otherwise) or de-identify it so that
          it is anonymous and not attributable to You. </p>

        <p><i>If You make a request, We have one month to respond to You. If You would like to exercise any of these
            rights, please contact us at our email: dpo@ghspl.com</i></p>
        <p><i>If You have (i) any questions about this Privacy Policy and/or (ii) any grievance with respect to the
            Services, You can contact us:</i></p>
        <ul>
          <li>By email: dpo@ghspl.com</li>
          <li>By Mail: Data Protection Officer and Grievance Officer, Glocal Healthcare Systems Private Limited 3B 207
            Ecospace Business Park, New Town Action Area II, New Town Kolkata 700156 India</li>
        </ul>
        <p>If there is a failure to get s response or a resolution, You may seek arbitration under the Arbitration &
          Conciliation Act, 1996 with a notice to dpo@ghspl.com. If You do not get a response within one month, You have
          the right to approach the Data Protection Commissioner or Authority in India.</p>

      </div>
    </div>
  </div>

</section>