<!-- <div>
  <h1>{{ "HOME.Your_application_Status" | translate }}:</h1>
  <div *ngIf="docappliStats === '4'">
    {{ "HOME.your_application" | translate }} <span> {{ docID }} </span>
    {{ "HOME.is_Pending_for_Approval" | translate }}
  </div>
  <div *ngIf="docappliStats === '5'">
    {{ "HOME.your_application" | translate }} <span> {{ docID }} </span>
    {{ "HOME.has_Rejected" | translate }}
  </div>
</div> -->

testing...
<h1>Heading ...</h1>
<ng-content></ng-content>
<h1>Heading ...</h1>
<h1>Heading ...</h1>
<h1>Heading ...</h1>