<body class="login_page">
  <div class="container">
    <div class="row">
      <div class="col-md-4 align-center">
        <div class="logo-holder">
          <img
            src="./assets/images/logo_litmusDx.png"
            alt="LitmusDX"
            width=""
            height=""
          />
        </div>
      </div>
      <div class="col-md-7">
        <div class="card bg-trnspWhite login-panel">
          <div class="card-header">
            <div class="row justify-content-md-center">
              <div class="col-12 text-center lang-dropdown">
                <label class="text-white">
                  {{ "HOME.Language" | translate }} &nbsp;&nbsp;
                  <select
                    class="form-control input-sm input-lang"
                    #langselect
                    (change)="translate.use(langselect.value)"
                  >
                    <option
                      *ngFor="let lang of translate.getLangs()"
                      [value]="lang"
                    >
                      <span *ngIf="lang === 'en'">English</span>
                      <span *ngIf="lang === 'fr'">French</span>
                      <span *ngIf="lang === 'ru'">Russian</span>
                      <span *ngIf="lang === 'por'">Portuguese</span>
                    </option>
                  </select>
                </label>
              </div>
            </div>

            <h3 class="card-title align-center text-white">
              {{ "HOME.Doctor_Login" | translate }}
            </h3>
          </div>
          <div class="card-body">
            <div class="inputs">
              <div class="row">
                <form
                  action=""
                  autocomplete="off"
                  [formGroup]="loginForm"
                  (ngSubmit)="onSubmit()"
                >
                  <ul class="input-holder form-group">
                    <li class="col-sm-12 col-lg-4">
                      <span class="font-medium-1 white">
                        {{ "HOME.Email" | translate }}
                      </span>
                    </li>
                    <li class="col-sm-12 col-lg-8">
                      <input
                        id="user_id"
                        class="form-control input-sm"
                        placeholder="{{ 'HOME.Email' | translate }}"
                        type="text"
                        formControlName="email"
                        [ngClass]="{
                          'is-invalid': submitted && f.email.errors
                        }"
                      />
                      <div *ngIf="submitted && f.email.errors" class="error">
                        <span *ngIf="f.email.errors.required">
                          {{ "HOME.User_name_is_required" | translate }}
                        </span>
                      </div>
                    </li>

                    <li class="col-sm-12 col-lg-4">
                      <span class="font-medium-1 white">
                        {{ "HOME.Password" | translate }}
                      </span>
                    </li>
                    <li class="col-sm-12 col-lg-8">
                      <input
                        id="myPassword"
                        type="password"
                        formControlName="password"
                        placeholder="{{ 'HOME.Password' | translate }}"
                        class="form-control input-sm"
                        [ngClass]="{
                          'is-invalid': submitted && f.password.errors
                        }"
                      />
                      <span
                        toggle="#myPassword"
                        class="paseye fa fa-fw fa-eye field-icon toggle-password"
                      ></span>
                      <div *ngIf="submitted && f.password.errors" class="error">
                        <span *ngIf="f.password.errors.required">
                          {{ "HOME.Password_is_required" | translate }}
                        </span>
                      </div>
                    </li>
                    <!-- captcha start -->
                    <li class="col-sm-12 col-lg-12 padding-15-lr">
                      <div class="row">
                        <div class="col-sm-3">
                          <div id="captcha_log"></div>
                        </div>
                        <div class="col-sm-1" style="margin-top: 5px">
                          <span
                            (click)="createCaptcha()"
                            style="cursor: pointer"
                            ><i
                              class="fas fa-redo-alt"
                              style="
                                cursor: pointer;
                                color: #fff;
                                font-size: 18px;
                              "
                            ></i
                          ></span>
                        </div>
                        <div class="col-sm-8">
                          <input
                            type="text"
                            autocomplete="new-password"
                            placeholder="{{ 'HOME.Captcha' | translate }}"
                            id="cpatchaTextLogin"
                            class="form-control input-sm"
                          />
                        </div>
                      </div>
                    </li>

                    <li class="col-sm-12 col-lg-4 login-space">&nbsp;</li>
                    <li class="col-sm-12 col-lg-8 login-methods">
                      <button
                        type="submit"
                        class="btn btn-md btn-success login-button"
                        [disabled]="loginButtonDisFlag"
                      >
                        {{ "HOME.Login" | translate }}
                      </button>
                      <div
                        (click)="openFaceLoginWindow()"
                        class="face-login"
                        title="Face Login"
                      >
                        <span>Face Login</span>
                        <img
                          src="./assets/images/face-recognition.png"
                          alt="LitmusDX"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div *ngIf="submitted && validateLogin">
                        <span>
                          <font style="color: red">
                            {{
                              "HOME.Invalid_user_name_or_Password" | translate
                            }}
                          </font>
                        </span>
                      </div>
                    </li>
                    <!-- <li>
                      <button
                        type="submit"
                        class="btn btn-md btn-success login-button"
                        [disabled]="loginButtonDisFlag"
                      >
                        Face Login
                      </button>
                    </li> -->

                    <li class="col-sm-12 col-lg-4 login-space">&nbsp;</li>
                    <li class="col-sm-12 col-lg-8 font-small-3 login-link">
                      <a role="button" (click)="openFPModal('forgotPassDlg')">
                        {{ "HOME.Forgot_your_password?" | translate }}
                      </a>
                      <span hidden style="float: right">
                        {{ "HOME.New_User" | translate }}?
                        <a routerLink="/new-register">
                          {{ "HOME.Sign_up" | translate }}
                        </a>
                      </span>
                    </li>
                    <li class="col-sm-12 col-lg-8 font-small-3 login-link">
                      <span style="float: right">
                        <!-- <a routerLink="/privacy-policy" class="privacy_btn"> -->
                        <a
                          href="https://www.hellolyf.com/privacy_policy.xhtm"
                          target="_blank"
                          class="privacy_btn"
                        >
                          {{ "HOME.Privacy_Policy" | translate }}
                        </a>
                      </span>
                    </li>
                  </ul>
                </form>
              </div>
              <hr class="partition" />
              <div class="row">
                <div class="col-md-12">
                  <h4 class="align-center font-medium-1 white login-text">
                    {{ "HOME.Find" | translate }} "{{
                      "HOME.Hellolyf_for_Doctors" | translate
                    }}" {{ "HOME.in_Playstore" | translate }}
                  </h4>
                  <ul class="icon-holder align-center">
                    <li>
                      <img
                        src="./assets/images/icon_googlePlay.svg"
                        class="google"
                      />
                    </li>
                    <li>
                      <img src="./assets/images/icon_qrCode.svg" class="qr" />
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="disclaimer">
                    <p>
                      <b>{{ "HOME.DISCLAIMER" | translate }}:</b>
                      {{
                        "HOME.The_contents_of_LitmusDx_are_for_informational"
                          | translate
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- card body ends--- -->
        </div>
        <!-- <div>
			{{this.loginForm.controls["email"].value | concatestr}}
		</div> -->
      </div>
    </div>
    <!-- row ends -->
  </div>

  <footer>
    <div class="container">
      <div class="row">
        <div class="col-12 col-xs-12">
          <p>{{ "HOME.Copyright_Glocal_Healthcare_Systems" | translate }}</p>
        </div>
      </div>
    </div>
  </footer>
</body>

<!-- loader on login request -->
<div id="loader-wrapper" *ngIf="loginLoader">
  <div id="loader"></div>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>

<!-- change password modal start from here -->
<jw-modal
  id="forgotPassDlg"
  spellcheck="true"
  class="testModal modal-sm"
  style="min-height: 250px"
>
  <div *ngIf="!updatePass">
    <div *ngIf="!verifyOTPValue">
      <div class="modal-header-line">
        <h3 class="dlgheader">{{ "HOME.Change_Password" | translate }}:</h3>
      </div>
      <ul class="login-list" style="padding: 15px">
        <li>
          <div class="row">
            <div class="col-md-4">{{ "HOME.Email" | translate }}:</div>
            <div class="col-8 col-md-5">
              <input
                type="text"
                id="email_idfp"
                (keyup)="changeEmail()"
                [(ngModel)]="emailForPassChange"
                size=""
                class="form-control input-sm"
              />
            </div>
            <div class="col-4 col-md-3">
              <button (click)="sendOtp()" class="btn btn-sm btn-success">
                {{ "HOME." + sendOtpStr | translate }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-md-4">{{ "HOME.Enter_OTP" | translate }}</div>
            <div class="col-8 col-md-5">
              <input
                type="text"
                [(ngModel)]="smsOTP"
                id="otp_fp"
                autocomplete="false"
                size=""
                class="form-control input-sm"
              />
            </div>
            <div class="col-4 col-md-3">
              <button (click)="verifyOTPFP()" class="btn btn-sm btn-info">
                {{ "HOME.Verify_OTP" | translate }}
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div [hidden]="!verifyOTPValue">
      <div class="modal-header-line">
        <h3 class="dlgheader">{{ "HOME.Update_Password" | translate }}:</h3>
        <h5 *ngIf="securePasswordChangeFlag">
          <strong
            >Your current login password strength is weak and is prone to
            attacks, please update to a more secure password.</strong
          >
        </h5>
      </div>
      <ul class="login-list" style="padding: 15px">
        <li>
          <div class="row">
            <div class="col-4 col-md-4">
              {{ "HOME.Enter_New_Password" | translate }}:
            </div>
            <div class="col-8 col-md-8">
              <input
                type="password"
                autocomplete="new-password"
                [(ngModel)]="newPass"
                placeholder="{{ 'HOME.New_Password' | translate }}"
                class="form-control input-sm"
                id="password-fieldS"
              />
              <span
                toggle="#password-fieldS"
                style="cursor: pointer"
                class="paseye fa fa-fw fa-eye field-icon toggle-password"
              ></span>
              <div id="errors"></div>
            </div>
            <!-- <div *ngIf="passwordValidErrorFlag" id="errors" style="margin-left: 20px;">
              <pre style="color:red;">
              Password must contain at least 1 Upper Case Letters.
              Password must contain at least 1 Lower Case Letters.
              Password must contain at least 1 Digits.
              Password must contain at least 1 Special Characters.
              Password must contain at least 6 characters.
            </pre>
            </div> -->
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-4 col-md-4">
              {{ "HOME.Confirm_Password" | translate }}:
            </div>
            <div class="col-8 col-md-8">
              <input
                type="password"
                autocomplete="new-password"
                [(ngModel)]="newPassCheck"
                placeholder="{{ 'HOME.New_Password' | translate }}"
                class="form-control input-sm"
                id="password-fieldC"
              />
              <span
                toggle="#password-fieldC"
                style="cursor: pointer"
                class="paseye fa fa-fw fa-eye field-icon toggle-password"
              ></span>
            </div>
          </div>
        </li>
        <!-- captcha -->
        <li>
          <div class="row">
            <div class="col-3 col-md-3">
              <div id="captcha_fp"></div>
            </div>
            <div class="col-1 col-md-1">
              <span (click)="createCaptchaforFP()" style="cursor: pointer"
                ><i class="fas fa-redo-alt" style="cursor: pointer"></i
              ></span>
            </div>
            <div class="col-8 col-md-8">
              <div class="captcha-box">
                <input
                  name="code_fp"
                  id="code_fp_text"
                  [(ngModel)]="capchaCodeFP"
                  class="form-control"
                  placeholder="Type The Code"
                />
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="col-12 text-center">
            <button
              id="valid"
              class="btn btn-sm btn-info"
              style="padding-top: 5px"
              (click)="changePassword()"
            >
              {{ "HOME.Update" | translate }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div style="text-align: center" *ngIf="updatePass">
    <h1 style="color: rgb(13, 216, 13)">
      {{ "HOME.Password_Update_Successfully" | translate }}!!
    </h1>
  </div>
  <div class="jw-modal-footer" *ngIf="!updatePass">
    <button (click)="closeFPModal()" class="btn btn-sm btn-danger">
      {{ "HOME.Close" | translate }}
    </button>
  </div>
  <div *ngIf="updatePass" class="jw-modal-footer" style="float: right">
    <button (click)="closeFPModal()" class="btn btn-sm btn-primary">
      {{ "HOME.Login" | translate }}
    </button>
  </div>
</jw-modal>

<!--- change mobile no modal start from here-->
<jw-modal id="mobVariModal" class="testModal modal-sm">
  <div *ngIf="mobileValidationFlag">
    <div class="modal-header-line text-center">
      <h3>
        {{ docDtl["presDocName"] }}!
        {{ "HOME.Is_this_your_Contact_No" | translate }}:
        {{ docDtl["userMobileNo"] }}?
      </h3>
    </div>
    <div class="text-center" style="padding-top: 30px">
      <button
        type="button"
        class="btn btn-sm btn-success"
        (click)="correctMob()"
      >
        {{ "HOME.YES" | translate }}
      </button>
      &nbsp;&nbsp;&nbsp;
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="incorrectMob()"
      >
        {{ "HOME.NO" | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="!mobileValidationFlag">
    <div class="modal-header-line">
      <h3>{{ "HOME.Please_Verify_your_Valid_No" | translate }}.:</h3>
    </div>
    <div>
      <ul class="login-list">
        <li>
          <h5>{{ "HOME.Enter_your_Mobile_No" | translate }}:</h5>
          <div class="row">
            <div class="col-3 col-md-2">
              <input
                type="text"
                [(ngModel)]="countryCode"
                size="3"
                class="form-control input-sm otp"
              />
            </div>
            <div class="col-9 col-md-6">
              <input
                type="text"
                [(ngModel)]="mobileNo"
                maxlength="12"
                (keyup)="changeMobileNo()"
                size="15"
                class="form-control input-sm phone-number"
              />
            </div>
            <div class="col-3 col-md-3 login-btn">
              <div *ngIf="!variOtpFlag">
                <button
                  class="btn btn-sm btn-primary"
                  type="button"
                  (click)="generateOTP()"
                >
                  {{ "HOME." + sendOtpStr | translate }}
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <h5>{{ "HOME.Enter_the_received_OTP" | translate }}:</h5>
          <div class="row">
            <div class="col-6 col-md-4">
              <input
                type="text"
                [(ngModel)]="smsOTP"
                size="5"
                maxlength="4"
                class="form-control input-sm otp"
              />
            </div>
            <div class="col-6 col-md-8">
              <span *ngIf="!variOtpFlag">
                <button
                  class="btn btn-sm btn-success"
                  type="button"
                  (click)="varificationOtp()"
                >
                  {{ "HOME.Verify_OTP" | translate }}
                </button>
              </span>
              <span *ngIf="variOtpFlag" class="success-text-vari">
                {{ "HOME.Verified" | translate }}!!!
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="!mobileValidationFlag">
    <div class="jw-modal-footer">
      <span *ngIf="!variOtpFlag && !invalidNoDB"
        ><button
          type="button"
          class="btn btn-sm btn-danger"
          (click)="incorrectMob()"
        >
          {{ "HOME.Back" | translate }}
        </button></span
      >
      <span *ngIf="variOtpFlag">
        <button
          type="button"
          (click)="proceedToDashboard()"
          class="btn btn-sm btn-success"
        >
          {{ "HOME.Proceed" | translate }}
        </button>
      </span>
    </div>
  </div>
</jw-modal>

<!-- Instruction to allow notification(fcm notification) for different broswers -->
<jw-modal id="NotificationModal" spellcheck="true" class="testModal modal-md">
  <div class="modal-header-line">
    <h2 class="dlgheader">
      {{ "HOME.Instruction_to_allow_Notification_in_browser" | translate }}:
    </h2>
  </div>
  <div class="modal-header-line">
    <h5 class="dlgheader">
      {{
        "HOME.It_seems_that_notification_permission_of_LitmusDX_application_is_blocked"
          | translate
      }}.
    </h5>
    <h5 class="dlgheader" style="color: red">
      {{ "HOME.Please_go_through_the_following_instruction" | translate }}:-
    </h5>
  </div>

  <div class="row" *ngIf="'Firefox' === usedBrowserName">
    <ol>
      <li>
        Click the menu button
        <span class="for" data-for="not fx57" style="display: none">
          <img
            alt="New Fx Menu"
            class="wiki-image frameless lazy"
            data-original-src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2014-01-10-13-08-08-f52b8c.png"
            src="https://static-media-prod-cdn.itsre-sumo.mozilla.net/static/sumo/img/placeholder.gif"
            title=""
          /> </span
        ><span class="for" data-for="fx57">
          <img
            alt="Fx57Menu"
            class="wiki-image frameless"
            data-original-src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2017-10-22-15-37-15-18c775.png"
            src="https://user-media-prod-cdn.itsre-sumo.mozilla.net/uploads/gallery/images/2017-10-22-15-37-15-18c775.png"
            title=""
          />
        </span>
        and select
        <span class="for" data-for="win"
          ><span class="button">Options</span>.</span
        ><span class="for" data-for="mac,linux" style="display: none"
          ><span class="button">Preferences</span>.</span
        >
      </li>
      <li>Click <strong>Privacy &amp; Security</strong> from the left pane.</li>
      <li>Scroll to the <strong>Permissions</strong> section.</li>
      <li>
        Click the <span class="button">Settings...</span> button to the right of
        <strong>Notifications</strong>.
      </li>
      <li>
        Select <strong>Allow</strong> or <strong>Block</strong> from the
        <strong>Status</strong> drop-down for any of the websites.
      </li>
      <li>Click the <span class="button">Save Changes</span> button.</li>
    </ol>
  </div>
  <div class="row" *ngIf="'Chrome' === usedBrowserName">
    <ol>
      <li data-outlined="false" class="">On your computer, open Chrome.</li>
      <li>
        At the top right, click More : and then <strong>Settings</strong>.
      </li>
      <li>
        Under "Privacy and security," click <strong>Site settings</strong>.
      </li>
      <li>Click <strong>Notifications</strong>.</li>
      <li>
        Choose to block or allow notifications:
        <ul>
          <li data-outlined="false" class="">
            <strong>Allow</strong> or <strong>Block all:</strong> Turn on or off
            <strong>Sites can ask to send notifications</strong>.
          </li>
          <li>
            <strong>Block a site:</strong> Next to "Block," click
            <strong>Add</strong>. Enter the site and click <strong>Add</strong>.
          </li>
          <li>
            <strong>Allow a site:</strong> Next to "Allow," click
            <strong>Add</strong>. Enter the site and click <strong>Add</strong>.
          </li>
          <li data-outlined="false" class="">
            <strong>Allow quieter notification prompts:</strong> Allow sites to
            ask to send notifications, then click
            <strong
              >Use quieter messaging (blocks notification prompts from
              interrupting you)</strong
            >. You won't get notifications after you ignore multiple
            notifications from a site, or if other users typically don't allow
            notifications from a site.
          </li>
        </ul>
      </li>
    </ol>
  </div>
  <div class="row" *ngIf="'Safari' === usedBrowserName">
    <ol>
      <li>
        <span
          >Select “Safari” in the action bar at the top of the screen and select
          “Preferences”</span
        >
      </li>
      <li>
        <span
          >Click on the “Websites” tab and then select “Notifications” in the
          menu on the left-side of the preferences window</span
        >
      </li>
      <li>
        <span
          >Here you can select a domain and either enable or disable
          notifications from that domain</span
        >
      </li>
    </ol>
  </div>
  <div class="row" *ngIf="'Opera' === usedBrowserName">
    <ol>
      <li>Go to&nbsp;<strong>Settings</strong>.</li>
      <li>
        Click <strong>Advanced</strong> in the left sidebar, and click
        <strong>Privacy &amp; security</strong>.
      </li>
      <li>
        Under <strong>Privacy and security</strong>, click&nbsp;<strong
          >Site settings</strong
        >.
      </li>
      <li>Click&nbsp;<strong>Notifications</strong>.</li>
      <li>
        At the top, turn on or off&nbsp;<strong
          >Ask before sending (recommended)</strong
        >.
      </li>
    </ol>
  </div>
  <div class="row" *ngIf="'MSIE' === usedBrowserName">
    <ol>
      <li>
        <span
          >Open Edge and select the “...” button in the top-right corner of the
          browser window</span
        >
      </li>
      <li>
        <span
          >Scroll down and select the “Settings” option from the dropdown
          menu</span
        >
      </li>
      <li>
        <span
          >Scroll down and select the “Advanced Settings” option from the
          dropdown menu</span
        >
      </li>
      <li>
        <span
          >Scroll down and select the “Manage” button under the “Website
          Permissions” heading</span
        >
      </li>
      <li>
        <span
          >Here you can select a domain and either enable or disable
          notifications from that domain</span
        >
      </li>
    </ol>
  </div>
  <div class="jw-modal-footer">
    <button
      (click)="closeNotictionInstructionModal()"
      class="btn btn-sm btn-danger"
    >
      {{ "HOME.Close" | translate }}
    </button>
  </div>
</jw-modal>

<!--- disclaimer message show-->
<jw-modal id="disclaimerModal" class="testModal modal-sm">
  <div class="modal-header-line">
    <h2 class="dlgheader">{{ "HOME.Important_points" | translate }}:</h2>
  </div>

  <div class="row" style="max-height: 300px; overflow-y: auto">
    <ol class="dcol">
      <li
        *ngFor="let messageItem of disclaimerMessagesList; let i = index"
        [innerHtml]="messageItem['message']"
      ></li>
    </ol>
    <div class="agree-panal-footer">
      <div class="disclaimer-agree-group">
        <span>
          <input
            type="checkbox"
            id="acceptdisclaimer"
            (ngModelChange)="agreeCheckboxChange($event)"
            [(ngModel)]="acceptDiscalimerFlag"
          />
          <label class="disclaimer-agree-text" for="acceptdisclaimer">{{
            "HOME.I_have_read" | translate
          }}</label>
        </span>
        <!-- <span class="disclaimer-agree-text">
          <p>I accept the disclaimer!!</p>
        </span> -->
      </div>
      <button
        (click)="disclaimerAgreeProcess()"
        [title]="disclaimerProceedBtnTitle"
        class="btn btn-sm btn-success disclaimer_btn"
        [disabled]="!acceptDiscalimerFlag"
      >
        {{ "HOME.Agree" | translate }}
      </button>
    </div>
  </div>

  <div class="jw-modal-footer">
    <!-- <div class="disclaimer-agree-group">
      <span>
        <input type="checkbox" id="acceptdisclaimer" (ngModelChange)="agreeCheckboxChange($event)"
          [(ngModel)]="acceptDiscalimerFlag">
        <label class="disclaimer-agree-text" for="acceptdisclaimer">{{ "HOME.I_have_read" | translate }}</label>
      </span>
    </div>
    <button (click)="disclaimerAgreeProcess()" [title]="disclaimerProceedBtnTitle"
      class="btn btn-sm btn-success disclaimer_btn" [disabled]="!acceptDiscalimerFlag">
      {{ "HOME.Agree" | translate }}
    </button> -->
  </div>
</jw-modal>
