import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { LiveHeaderService } from './live-header.service';
@Injectable({
  providedIn: 'root'
})
export class AppInterceptorService implements HttpInterceptor {

  constructor(
    private headerService: LiveHeaderService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //console.log("request pre::", req);
    const userToken = localStorage.getItem('JWT_KEY');
    let jwtSecureToken = 'Bearer ' + userToken;
    const modifiedReq = req.clone({
      headers: req.headers.set('Authorization', jwtSecureToken),
    });
    //console.log('request for api modifiedreq::', modifiedReq)
    return next.handle(modifiedReq).pipe(tap((event: HttpEvent<any>) => {
      return;
    },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401 && err.status !== 403) {
            return;
          }
          this.toastrService.info(this.translate.instant('HOME.Logging_Out') + "...");
          this.toastrService.info(this.translate.instant('HOME.Session_Expired') + "!!");
          //clear session values
          sessionStorage.removeItem('LITMUS_USER_DETAILS');
          localStorage.removeItem("JWT_KEY");
          this.router.navigate(["/", "login"]);
        }
      }));
  }
}
