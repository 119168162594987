import { Injectable } from '@angular/core';
import { DoctorActivityDetailsDto } from '../model/doctor-activity-details-dto';
import { Router } from '@angular/router';
import { ResponseDto } from '../model/response-dto';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DocActivityRequestDto } from '../model/doc-activity-request-dto';
import { ApiConstants } from '../model/api-constants';

@Injectable({
	providedIn: 'root'
})
export class LiveHeaderService {
	private respoDto: ResponseDto;

	constructor(
		private router: Router,
		private http: HttpClient,
	) {
		this.respoDto = new ResponseDto();
	}
	addActivityAPI(docActivityDtlDto: DoctorActivityDetailsDto): Observable<ResponseDto> {
		let apiEndpoint = ApiConstants.apiEndPoint + '/docactivity/addActivity';
		console.log("api address===>" + apiEndpoint);
		return this.http.post<ResponseDto>(apiEndpoint, docActivityDtlDto);
	}

	getIpAdd() {
		//console.log("getIpAdd called");
		return this.http.get("https://api.ipify.org?format=json");
	}
	sessionChecker(): string {
		let returnStr: string;
		let loginVO = JSON.parse(sessionStorage.getItem("LITMUS_USER_DETAILS"));
		if (loginVO === null || loginVO['userCustomerId'] === null) {
			returnStr = "EXPIRED";
		} else {
			returnStr = "NOTEXPIRED";
		}
		return returnStr;
	}

	putDocInBreak(activityReqDto: DocActivityRequestDto): Observable<ResponseDto> {
		let apiEndpoint = ApiConstants.apiEndPoint + "/login/doctakeabreak";
		//console.log("api address===>" + apiEndpoint);
		return this.http.put<ResponseDto>(apiEndpoint, activityReqDto);
	}

	putDocOnline(activityReqDto: DocActivityRequestDto): Observable<ResponseDto> {
		let apiEndpoint = ApiConstants.apiEndPoint + "/login/makemeonline";
		//console.log("api address===>" + apiEndpoint);
		return this.http.put<ResponseDto>(apiEndpoint, activityReqDto);

	}

	putUserLogout(nextavailablityTime: string): Observable<ResponseDto> {
		let userDtl = JSON.parse(sessionStorage.getItem("LITMUS_USER_DETAILS"));
		let reqDto = new DocActivityRequestDto();
		reqDto.setDoctorId(String(userDtl['doctorId']));
		reqDto.setSessionId(String(userDtl['sessionId']));
		reqDto.setUserCustomerId(String(userDtl['userCustomerId']));
		reqDto.setLoginMode("A");
		reqDto.setLogoutBy("User");
		reqDto.setNextAvailableTime(nextavailablityTime);
		//console.log("input dto logout:: live header service::", reqDto);
		//this.destorySession();
		return this.http.put<ResponseDto>(ApiConstants.apiEndPoint + "/login/doclogoutangular", reqDto);
	}

	// destroy session after logout ===>
	destorySession() {
		this.http.delete<ResponseDto>(ApiConstants.apiEndPoint + "/login/logout").subscribe(response => {
			console.log("logout destroy response ===>", response);
		}, error => {
			console.log("Error:: ", error);
		});
	}

	//clear cookie before logout
	deleteCookie(name: string) {
		console.log("clear cookie===>" + name);
		const date = new Date();

		// Set it expire in -1 days
		date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

		// Set it
		console.log("delete cookie===>" + name + "=; expires=" + date.toUTCString() + "; path=/glclapitest");
		document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/glclapitest";
	}

	// update new password
	updateNewPass(reqDto: { docId: string; newPassword: string; userCustomerId: string; oldPassword:string; }): Observable<any> {
		let sessionObj = JSON.parse(sessionStorage.getItem("LITMUS_USER_DETAILS"));

		let headers: HttpHeaders = new HttpHeaders();

		let sessionId = String(sessionObj['sessionId']);
		headers = headers.append('sessionId', sessionId);
		return this.http.put<any>(ApiConstants.apiEndPoint + "/login/doc/changepassword", reqDto, { headers });
	}
}