import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DoctorContactDto } from '../model/doctor-contact-dto';
import { DocContactInfoUpDto } from '../model/doc-contact-info-up-dto';
import { ApiConstants } from '../model/api-constants';

@Injectable({
  providedIn: 'root'
})
export class DocMobVarifictionService {

  constructor(private http: HttpClient,) {

  }
  generateOTP(mobileNo: string): Observable<DoctorContactDto> {
    //replace any to class docContact info==>POST /login/doctorcontactconfirmation
    return this.http.post<DoctorContactDto>(ApiConstants.apiEndPoint + "/login/doctorcontactconfirmation", {
      "countryCode": "+91",
      "mobileNo": mobileNo,
      "otp": "",
      "smsSender": ""
    });
  }
  updateMobileNo(obj: DocContactInfoUpDto) {
    //update POST /login/updatedoctorcontactconfirmation
    return this.http.post(ApiConstants.apiEndPoint + "/login/updatedoctorcontactconfirmation", obj, { responseType: 'text' });
  }
}
