import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Doclogindtl } from '../model/doclogindtl';
import { User } from '../model/user';
import { AuthenticationService } from './authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseDto } from '../model/response-dto';
import { ApiConstants } from '../model/api-constants';


@Injectable({
  providedIn: 'root'
})
export class CustomerLoginService {
  private returnMsg: string;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  getLitmusUserLoginDtl(userInput: Doclogindtl): Observable<User> {
    return this.http.post<User>(ApiConstants.apiEndPoint + "/login/doc", userInput);
  }

  generateOtpFP(docMailId: string): Observable<ResponseDto> {
    return this.http.post<ResponseDto>(ApiConstants.apiEndPoint + "/login/docfpotp", docMailId);
  }

  updatePassword(docMailId: string, newPass: string): Observable<any> {
    let obj = {
      "doctorMailId": docMailId,
      "newPassword": newPass
    };
    return this.http.put<any>(ApiConstants.apiEndPoint + "/login/doc/forgotpassword", obj);
  }

  userLogin(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(ApiConstants.apiEndPoint + "/user/login", { observe: "response" });
  }

  // get disclaimer message
  getDisclaimerMessages(userType: string): Observable<any[]> {
    return this.http.get<any[]>(ApiConstants.apiEndPoint + "/login/getLoginDisclaimerMessages/" + userType);
  }

}