import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LitUserMasterDto } from '../model/lit-user-master-dto';
import { Observable } from 'rxjs';
import { QueuePatientDtlDto } from '../model/queue-patient-dtl-dto';
import { Language } from '../model/language';
import { ApiConstants } from '../model/api-constants';
import { ResponseDto } from '../model/response-dto';
import { ConsultStatusUpdateDto } from '../model/consult-status-update-dto';

@Injectable({
  providedIn: 'root'
})
export class PatientQueueManageService {
  constructor(
    private http: HttpClient,
  ) { }
  getPatientList(): Observable<QueuePatientDtlDto[]> {
    //console.log("service called");
    let reqDto = new LitUserMasterDto();
    let headers: HttpHeaders = new HttpHeaders();
    let sessionObj = JSON.parse(sessionStorage.getItem("LITMUS_USER_DETAILS"));
    reqDto.setDocId(String(sessionObj['doctorId']));
    let sessionId = String(sessionObj['sessionId']);
    headers = headers.append('sessionId', sessionId);
    //console.log("headers===>"+JSON.stringify(headers));

    //take docId and sessionId
    return this.http.post<QueuePatientDtlDto[]>(ApiConstants.apiEndPoint + "/patientlist", String(sessionObj['doctorId']), { headers });
  }

  resetSessionValues() {
    sessionStorage.removeItem("PatientQueueList");
    sessionStorage.removeItem("QueueCount");
  }



  //update start consultation time ===>
  updateStartTime(dto: ConsultStatusUpdateDto): Observable<ResponseDto> {
    //console.log("sessionId ===>" + dto['sessionId']);
    let headers = new HttpHeaders().append("sessionId", dto['sessionId']);
    return this.http.put<ResponseDto>(ApiConstants.apiEndPoint + "/consultation/timeupdate", dto, { headers });
  }

  //update start consultation time ===>
    updateStartTimeWeb(dto: ConsultStatusUpdateDto): Observable<ResponseDto> {
      //console.log("sessionId ===>" + dto['sessionId']);
      let headers = new HttpHeaders().append("sessionId", dto['sessionId']);
      return this.http.put<ResponseDto>(ApiConstants.apiEndPoint + "/consultation/timeupdatelitmus", dto, { headers });
    }


  //test test test ===>
  getDataWithoutOb(): any {
    //console.log("api call ===>");
    return this.http.get<Language>(ApiConstants.apiEndPoint + "/language").subscribe(res => {
      //console.log("respone", res);
      return res;
    }, error => {
      //console.log("api error", error);
      return "errrorrrrorooror";
    });
  }

  getListofDoc(dto: { centerId: string; demoFlag: string; clientId: string; doctorId: string; }): Observable<any> {
    console.log(dto)
    return this.http.post<any>(ApiConstants.apiEndPoint + "/switchDoctor/doctorlistforswitching", dto);
  }

  switchToOtherDoctor(reqObj: { newDoctorMailId: string; originalDoctorLitId: string; patientVisitId: string; }[]): Observable<any> {
    return this.http.post<any>(ApiConstants.apiEndPoint + "/switchDoctor/switchingPatients", reqObj);
  }

  // get patient video status
  getPatientVideoStatus(transctionId: string): Observable<any> {
    return this.http.get<any>(ApiConstants.apiEndPoint + "/consultation/checkPatientInVideoOrNot/" + transctionId);
  }

  // verify patient info 
  doctorVerifyPatInfo(patientVisitId: string, approveFlag: string): Observable<any> {
    return this.http.put<any>(ApiConstants.apiEndPoint + "/patientlist/docverifystatus/" + patientVisitId + "/" + approveFlag, {});
  }

}
