import { Injectable } from '@angular/core';
import { DocSpecilization } from '../model/doc-specilization';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Language } from '../model/language';
import { DocQualification } from '../model/doc-qualification';
import { DocPreRegDtl } from '../model/doc-pre-reg-dtl';
import { ResponseDto } from '../model/response-dto';
import { ApiConstants } from '../model/api-constants';
import { Facility } from '../model/facility-id';

@Injectable({
  providedIn: 'root'
})
export class RegisterationService {
  constructor(private http: HttpClient) {
  }
  getDocSpecilazion(): Observable<DocSpecilization[]> {
    var str: string = ApiConstants.apiEndPoint + "/docspecilizations/available";
    //console.log("api address==>"+str);
    return this.http.get<DocSpecilization[]>(str);
  }

  getLanguageList(): Observable<Language[]> {
    return this.http.get<Language[]>(ApiConstants.apiEndPoint + "/language");
  }

  getDocQualificationList(): Observable<DocQualification[]> {
    return this.http.get<DocQualification[]>(ApiConstants.apiEndPoint + "/docqualifications");
  }

  sendOtp(strJson: DocPreRegDtl): Observable<DocPreRegDtl> {
    return this.http.post<DocPreRegDtl>(ApiConstants.apiEndPoint + "/docregs/pre", strJson);
  }

  registerDoc(dto: any): Observable<ResponseDto> {
    return this.http.post<ResponseDto>(ApiConstants.apiEndPoint + "/docregs", dto);
  }

  // user profile data form api using doctorid
  getUserProfileData(doctorId: string): Observable<any> {
    return this.http.get<any>(ApiConstants.apiEndPoint + "/docapps/profileExtra/" + doctorId);
  }

  // update profile to data base
  updateUserProfile(reqDto: { award: string; docSignImageChange: any; docSignImgFileData: any; docSignImgFileName: any; doctorId: string; expYr: any; experience: string; fees: string; mobileNo: string; profileImageChange: any; profileImgFileData: any; profileImgFileName: any; userMailId: any; qualification: any; }): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    let sessionObj = JSON.parse(sessionStorage.getItem("LITMUS_USER_DETAILS"));
    let sessionId = String(sessionObj['sessionId']);
    headers = headers.append('sessionId', sessionId);
    return this.http.put<any>(ApiConstants.apiEndPoint + "/docapps/profile", reqDto, { headers });
  }
  //api call for get Facilities .....
  getFacilities(userId: string): Observable<Facility[]> {
    //console.log("api doctorIddoctorIddoctorIddoctorIddoctorIddoctorId==>"+doctorId);
    var str: string = ApiConstants.apiEndPoint + "/facility/facilitiesOfUserForRefer/"+userId;
    return this.http.get<Facility[]>(str);
  }
}
