import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RegisterFormComponent } from './register-form/register-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthenticationService } from './_service/authentication.service';
import { AlertService } from './_service/alert.service';
import { CustomerLoginService } from './_service/customer-login.service';
import { TestDirective } from './_directive/test.directive';
import { ModalComponent } from './_directive/modal/modal.component';
import { DialogOpenService } from './_service/dialog-open.service';
import { RegisterationService } from './_service/registeration.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { DashboardService } from './_service/dashboard.service';
import { LiveHeaderService } from './_service/live-header.service';
import { ApplicationStatusComponent } from './application-status/application-status.component';
import { PatientQueueManageService } from './_service/patient-queue-manage.service';
import { MessagingService } from './_service/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { DocMobVarifictionService } from './_service/doc-mob-varifiction.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInterceptorService } from './_service/app-interceptor.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlphabateOnlyDirective } from './_directive/alphabate-only.directive';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';


// import { FilterPipe } from './_pipe/filter.pipe';
//import {OverlayModule} from '@angular/cdk/overlay';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    RegisterFormComponent,
    ForgotPasswordComponent,
    TestDirective,
    ModalComponent,
    ApplicationStatusComponent,
    AlphabateOnlyDirective,
    PrivacyPolicyComponent
   
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    HttpClientJsonpModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NgbModule,
    NgxDocViewerModule,
    
    //UserIdleModule.forRoot({idle: 3600, timeout: 30, ping: 3}), // session will time out after idle time(1 hour) and user did not interact with system in that time ... idle time in seconds.
    AngularFireModule.initializeApp(environment.firebase),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 5000
    }),
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
    //OverlayModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true
    },
    AuthenticationService,
    AlertService,
    CustomerLoginService,
    DialogOpenService,
    RegisterationService,
    DashboardService,
    LiveHeaderService,
    PatientQueueManageService,
    MessagingService,
    AsyncPipe,
    DocMobVarifictionService,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
