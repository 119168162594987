import { Injectable, EventEmitter } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { ResponseDto } from '../model/response-dto';
import { ApiConstants } from '../model/api-constants';
import firebase from 'firebase/app';
import '@firebase/messaging';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  messaging = firebase.messaging();
  currentMessage = new BehaviorSubject(null);


  public onChange: EventEmitter<any> = new EventEmitter<any>();
  public readonly fcmPermission = "false";

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
  ) {
    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    console.log("update token called====> token::" + token + " userid::" + userId);
    //we will change this function to put data in database not in firestore database
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token;
        this.angularFireDB.object('fcmTokens/').update(data);
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    console.log("api called to request token===>userid==>" + userId);
    // this.angularFireMessaging.requestToken.subscribe(
    //   (token) => {
    //     console.log("you will get token from firebase===> token:===>", token);
    //     if (token && "" !== token) {
    //       sessionStorage.setItem("FCMTOKEN", token);
    //     }

    //     //call this function to save token on firebase database
    //     //this.updateToken(userId, token);
    //   },
    //   (err) => {
    //     console.error('Unable to get permission to notify.', err);
    //     if (sessionStorage.getItem('FCMTOKEN')) {
    //       sessionStorage.removeItem('FCMTOKEN');
    //     }
    //   }
    // );

    this.messaging.requestPermission().then(() => {
      console.log("notificaiton permission granted!!");
      return this.messaging.getToken();
    }).then((token) => {
      console.log("fcm token:::", token);
      if (token && "" !== token) {
        sessionStorage.setItem("FCMTOKEN", token);
      }

      //call this function to save token on firebase database
      //this.updateToken(userId, token);

    }).catch((error) => {
      console.error("Unable to get permission to notify.", error);
      if (sessionStorage.getItem('FCMTOKEN')) {
        sessionStorage.removeItem('FCMTOKEN');
      }
    });

  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    console.log("receiveMessage called===>");
    // this.angularFireMessaging.messages.subscribe(
    //   (payload) => {
    //     console.log("new message received. ", payload);
    //     if (payload['notification']['title'] === "Logout User") {
    //       //clear local storage and cookies as well
    //       sessionStorage.removeItem("LITMUS_USER_DETAILS");
    //       this.destorySession();
    //     } else {
    //       this.onChange.emit(payload);
    //     }
    //     //alert("new patient in queue");
    //     this.currentMessage.next(payload);
    //   });

    this.messaging.onMessage((payload) => {
      console.log("new message received. ", payload);
      if (payload['notification']['title'] === "Logout User") {
        //clear local storage and cookies as well
        sessionStorage.removeItem("LITMUS_USER_DETAILS");
        this.destorySession();
      } else {
        this.onChange.emit(payload);
      }
      //alert("new patient in queue");
      this.currentMessage.next(payload);
    });
  }

  // destroy session after logout ===>
  destorySession() {
    this.http.delete<ResponseDto>(ApiConstants.apiEndPoint + "/login/logout").subscribe(response => {
      //console.log("logout destroy response ===>", response);
    }, error => {
      console.log("Error:: ", error);
    });
  }

  //clear cookie before logout
  deleteCookie(name: string) {
    //console.log("clear cookie===>" + name);
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    console.log("delete cookie===>" + name + "=; expires=" + date.toUTCString() + "; path=/glclapitest");
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/glclapitest";
  }

  // token will save to database corrosponding to doctorId through api 
  updateTokenToDB(userid: string): Observable<ResponseDto> {
    let dto = {
      tokenId: String(sessionStorage.getItem("FCMTOKEN")),
      doctorId: userid
    }
    //console.log("update token===> dto :: ", dto);
    return this.http.put<ResponseDto>(ApiConstants.apiEndPoint + "/docapps/fcmtoken", dto);
  }


}